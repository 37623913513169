<template>
  <div v-if="!loading">
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('purchaseOrder') }}
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-badge
          class="mr-1"
          :variant="
            $helpers.quoteStatusColor(purchaseOrders[0].quote.quote_status)
          "
        >
          {{ $helpers.quoteStatusName(purchaseOrders[0].quote.quote_status) }}
        </b-badge>
        <b-badge
          :variant="
            $helpers.paymentStatusColor(purchaseOrders[0].quote.payment_status)
          "
        >
          {{
            $helpers.paymentStatusName(purchaseOrders[0].quote.payment_status)
          }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <checkout
          :quote="purchaseOrderQuote"
          :project="project"
          :items="purchaseOrdersItems"
          :address="purchaseOrderAddress"
          :tax-info="project.tax_information || {}"
          :supplier-data="supplierData"
        />
      </b-col>
    </b-row>
    <b-row v-if="purchaseOrders.length === 0">
      <b-col>
        <h4 class="display-4">
          No hay Cotizaciones 🧟
        </h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Checkout from '@/components/invoice/Checkout.vue'

export default {
  components: {
    Checkout,
  },

  data() {
    return {
      loading: false,
      purchaseOrderQuote: {},
      purchaseOrderAddress: {},
      purchaseOrdersItems: [],
      supplierData: [], // Nueva propiedad para almacenar datos del proveedor
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('purchaseOrders', ['purchaseOrders']),
    ...mapGetters('projects', ['project']),
  },

  created() {
    this.loading = true
    this.fetchPurchaseOrders({
      quoteId: this.$route.params.quoteId,
    }).then(response => {
      if (response.data.length) {
        const projectId = response.data[0].quote.project_id
        const suppliers = []
        for (let i = 0; i < response.data.length; i += 1) {
          const items = response.data[i].purchase_order_items.map(order => {
            if (order.selected_supplier_branch_id === null) {
              return {
                ...order,
                supplier: 'No hay proveedor',
              }
            }
            if (!suppliers.includes(order.selected_supplier_branch_id)) {
              suppliers.push(order.selected_supplier_branch_id)
            }
            return {
              ...order,
              supplier: ['admin', 'auctioneer', 'supplier_admin', 'customer'].includes(
                this.currentUser.role_name,
              )
                ? response.data[i].supplier_branch.name
                : `Proveedor ${suppliers.findIndex(
                  supplierId => supplierId === order.selected_supplier_branch_id,
                ) + 1}`,
            }
          })
          this.purchaseOrdersItems = this.purchaseOrdersItems.concat(items)
        }
        const order = response.data[0]
        // If current user is auctioneer we show purchase order data (no commission)
        if (['auctioneer', 'supplier_admin'].includes(this.currentUser.role_name)) {
          order.iva_total = order.iva
          order.shipping_total = order.iva
          this.purchaseOrderQuote = { ...order }
        } else {
        // if user is admin we show quote data (with commissions)
          this.purchaseOrderQuote = order.quote
        }
        this.setPurchaseOrder(order)
        this.purchaseOrderAddress = order.address
        this.supplierData = response.data.map(item => item.supplier_branch) // Aquí obtienes los datos de los proveedores
        this.fetchProject(projectId).then(() => {
          this.loading = false
        })
      }
    })
    this.getQuotePayments({ quoteId: this.$route.params.quoteId })
  },

  methods: {
    ...mapActions('purchaseOrders', [
      'fetchPurchaseOrders',
      'fetchPurchaseOrder',
    ]),
    ...mapActions('quotes', ['getQuotePayments']),
    ...mapActions('projects', ['fetchProject']),
    ...mapMutations('purchaseOrders', {
      setPurchaseOrder: 'SET_PURCHASE_ORDER',
    }),
  },
}
</script>

<style></style>
